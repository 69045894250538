import React from "react"

export const WorkEx = ({ title, date, position, tasks }) => {
  return (
    <div className="exp">
      <div className="info">
        <h5>{title}</h5>
        <p>{date}</p>
      </div>
      <p className="role">{position}</p>
      <ul>
        {tasks &&
          tasks.map((task, index) => {
            return (
              <li key={index} className="activity">
                {task}
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export const Project = ({ title, description }) => {
  return (
    <div className="project">
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  )
}

export const Skills = ({ skills }) => {
  return (
    <ul className="skills">
      {skills.map(skill => {
        return (
          <li key={skill.name}>
            <h5>{skill.name}</h5>
            <div className="skill-list">
              {skill.keywords?.map(highlight => (
                <span className="skill">{highlight}</span>
              )) ?? null}
            </div>
          </li>
        )
      })}
    </ul>
  )
}
