export const skills = [
  {
    level: "",
    keywords: ["Javascript / Typescript", "Python", "Golang"],
    name: "Languages",
  },
  {
    level: "",
    keywords: [
      "HTML",
      "SCSS / CSS / styled-components / JSS",
      "React / Next JS",
      "React Query",
      "React Router",
      "Redux",
    ],
    name: "Frontend",
  },
  {
    level: "",
    keywords: ["Express JS", "Prisma", "Django", "Flask"],
    name: "Backend",
  },
  {
    level: "",
    keywords: [
      "Amazon Web Services",
      "Vercel",
      "Docker",
      "Git",
      "VS Code  / Vim",
      "Bash / Zsh Scripting",
    ],
    name: "Tools I've Used",
  },
  {
    keywords: [
      "Technology",
      "Cooking",
      "Cycling",
      "Reading",
      "Carpentry",
      "Travel",
      "Adventure Sports",
      "Video Games",
    ],
    name: "Interests",
  },
]
