import { graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { Project, Skills, WorkEx } from "../components/resumeComponents"
import SEO from "../components/seo"
import "../sass/main.scss"
import { skills } from "../constants"

const ResumePage = ({ data }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true)
    }, 16)
    return () => {
      setLoaded(false)
      clearTimeout(timer)
    }
  }, [])

  return (
    <Layout>
      <SEO title="Resume" />
      <div className="grid-container">
        <div className={`grid-x align-center resume ${loaded ? "loaded" : ""}`}>
          <div className="cell small-12 medium-10 large-6 paper">
            <div className="section">
              <h3>Work Experience</h3>
              {data.allResumeJson.edges.map(({ node }) => {
                return (
                  <WorkEx
                    key={node.id}
                    title={node.company}
                    date={`${node.startDate} - ${node.endDate || "Present"}`}
                    position={node.position}
                    tasks={node.highlights}
                  />
                )
              })}
            </div>

            <div className="section">
              <h3>Projects</h3>
              {data.allProjectsJson.edges.map(({ node }) => {
                return (
                  <Project
                    key={node.id}
                    title={node.title}
                    description={node.excerpt}
                  />
                )
              })}
            </div>

            <div className="section">
              <h3>Education</h3>
              <div className="education">
                <div className="info">
                  <h4>University of Mumbai</h4>
                  <h5>May, 2020</h5>
                </div>
                <div className="info">
                  <p>B.Sc. Information Technology</p>
                  <p>CGPA 9.25</p>
                </div>
              </div>
            </div>

            <div className="section">
              <h3>Skills</h3>
              <Skills skills={skills} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query resume {
    allProjectsJson {
      edges {
        node {
          id
          title
          excerpt
          date(formatString: "DD MMM, YYYY.")
        }
      }
    }
    allResumeJson(sort: { fields: startDate, order: DESC }) {
      edges {
        node {
          id
          company
          position
          endDate(formatString: "MMM YYYY")
          startDate(formatString: "MMM YYYY")
          summary
          website
          location
          highlights
        }
      }
    }
  }
`

export default ResumePage
